import axios from "axios";

// services
import headers from "services/Headers";

const getReviews = () => {
  return axios.get(`${process.env.REACT_APP_API}/admin/reviews`, headers());
};

export { getReviews };
