import { Card, Typography } from "@material-tailwind/react";

// services
import { confirmModal } from "services/general";

// components
import TFade from "components/Transition/Fade";
import Button from "components/Button";
import Modal from "components/Modal";

// assets
import thumbnail from "assets/images/thumbnail.png";
import { useState } from "react";

const DetailMovie = () => {
  const [showModalInsert, setShowModalInsert] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);

  const TABLE_HEAD = ["No", "Tanggal", "Waktu", "Aksi"];

  const TABLE_ROWS = [
    {
      no: "1",
      date: "23/04/18",
      time: "08:00",
    },
    {
      no: "2",
      date: "23/04/18",
      time: "10:00",
    },
    {
      no: "3",
      date: "23/04/18",
      time: "12:00",
    },
  ];

  const deleteShowtime = () => {
    confirmModal().then((result) => {
      if (result.isConfirmed) {
      }
    });
  };
  return (
    <>
      <TFade>
        <h1 className="mb-10 text-3xl font-semibold">Detail Film</h1>

        <div className="mb-14">
          <img
            src={thumbnail}
            alt="thumbnail"
            className="mb-10 h-[214px] w-[413px] rounded-lg object-cover"
          />

          <h5 className="mb-5 text-xl font-medium text-accent-black">
            SPIDERMAN : NO WAY HOME
          </h5>

          <table>
            <tr>
              <td className="pr-10">Genre</td>
              <td>Action</td>
            </tr>
            <tr>
              <td className="pr-10">Durasi</td>
              <td>2 jam 28 menit</td>
            </tr>
            <tr>
              <td className="pr-10">Sutradara</td>
              <td>Jon Watts</td>
            </tr>
            <tr>
              <td className="pr-10">Rating Usia</td>
              <td>R13+</td>
            </tr>
          </table>
        </div>

        <div>
          <div className="mb-5 flex items-center justify-between gap-4">
            <h5 className="text-xl font-medium text-accent-black">
              Jadwal Tayang Film
            </h5>
            <div
              onClick={() => setShowModalInsert(true)}
              className="action bg-dark-blue"
            >
              <svg
                width="19"
                height="19"
                viewBox="0 0 19 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19 10.8571H10.8571V19H8.14286V10.8571H0V8.14286H8.14286V0H10.8571V8.14286H19V10.8571Z"
                  fill="white"
                />
              </svg>
            </div>
          </div>

          <Card className="h-full w-full overflow-scroll">
            <table className="w-full min-w-max table-auto text-left">
              <thead>
                <tr>
                  {TABLE_HEAD.map((head) => (
                    <th
                      key={head}
                      className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                    >
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal leading-none opacity-70"
                      >
                        {head}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {TABLE_ROWS.map(({ no, date, time }, index) => {
                  const isLast = index === TABLE_ROWS.length - 1;
                  const classes = isLast
                    ? "p-4"
                    : "p-4 border-b border-blue-gray-50";

                  return (
                    <tr key={no}>
                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {no}
                        </Typography>
                      </td>
                      <td className={`${classes} bg-blue-gray-50/50`}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {date}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {time}
                        </Typography>
                      </td>
                      <td
                        className={`${classes} flex flex-row gap-5 bg-blue-gray-50/50`}
                      >
                        <div
                          onClick={() => setShowModalEdit(true)}
                          className="action bg-yellow"
                        >
                          <svg
                            width="19"
                            height="15"
                            viewBox="0 0 19 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M13.9286 0L11.6071 1.875L16.25 5.625L18.5714 3.75L13.9286 0ZM9.28571 3.75L0 11.25V15H4.64286L13.9286 7.5L9.28571 3.75Z"
                              fill="white"
                            />
                          </svg>
                        </div>
                        <div
                          onClick={() => deleteShowtime()}
                          className="action bg-red"
                        >
                          <svg
                            width="21"
                            height="20"
                            viewBox="0 0 21 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9 0C7.35 0 6 1.07853 6 2.39674H3C1.35 2.39674 0 3.47527 0 4.79348H21C21 3.47527 19.65 2.39674 18 2.39674H15C15 1.07853 13.65 0 12 0H9ZM3 7.19022V18.7185C3 18.9822 3.24 19.1739 3.57 19.1739H17.46C17.79 19.1739 18.03 18.9822 18.03 18.7185V7.19022H15.03V15.5788C15.03 16.2499 14.37 16.7772 13.53 16.7772C12.69 16.7772 12.03 16.2499 12.03 15.5788V7.19022H9.03V15.5788C9.03 16.2499 8.37 16.7772 7.53 16.7772C6.69 16.7772 6.03 16.2499 6.03 15.5788V7.19022H3.03H3Z"
                              fill="white"
                            />
                          </svg>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Card>
        </div>
      </TFade>

      <Modal
        name="Tambah Jadwal Tayang"
        showModal={showModalInsert}
        setShowModal={(value) => setShowModalInsert(value)}
        body={
          <>
            <form>
              <div className="mb-5">
                <label
                  htmlFor="date"
                  className="text-gray-900 block text-sm font-medium leading-6"
                >
                  Tanggal
                </label>
                <div className="mt-2">
                  <input
                    id="date"
                    name="date"
                    type="date"
                    placeholder="Masukkan Tanggal"
                    required
                    className="text-gray-900 ring-gray-300 placeholder:text-gray-400 block w-full rounded-md border-0 py-2 px-2 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="mb-5">
                <label
                  htmlFor="time"
                  className="text-gray-900 block text-sm font-medium leading-6"
                >
                  Waktu
                </label>
                <div className="mt-2">
                  <input
                    id="time"
                    name="time"
                    type="time"
                    placeholder="Masukkan Waktu"
                    required
                    className="text-gray-900 ring-gray-300 placeholder:text-gray-400 block w-full rounded-md border-0 py-2 px-2 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <Button type="submit" isBlock isPrimary>
                Kirim
              </Button>
            </form>
          </>
        }
      />

      <Modal
        name="Edit Jadwal Tayang"
        showModal={showModalEdit}
        setShowModal={(value) => setShowModalEdit(value)}
        body={
          <>
            <form>
              <div className="mb-5">
                <label
                  htmlFor="date"
                  className="text-gray-900 block text-sm font-medium leading-6"
                >
                  Tanggal
                </label>
                <div className="mt-2">
                  <input
                    id="date"
                    name="date"
                    type="date"
                    placeholder="Masukkan Tanggal"
                    required
                    className="text-gray-900 ring-gray-300 placeholder:text-gray-400 block w-full rounded-md border-0 py-2 px-2 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="mb-5">
                <label
                  htmlFor="time"
                  className="text-gray-900 block text-sm font-medium leading-6"
                >
                  Waktu
                </label>
                <div className="mt-2">
                  <input
                    id="time"
                    name="time"
                    type="time"
                    placeholder="Masukkan Waktu"
                    required
                    className="text-gray-900 ring-gray-300 placeholder:text-gray-400 block w-full rounded-md border-0 py-2 px-2 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <Button type="submit" isBlock isPrimary>
                Kirim
              </Button>
            </form>
          </>
        }
      />
    </>
  );
};

export default DetailMovie;
