import axios from "axios";

// services
import headers from "services/Headers";

const getMovies = () => {
  return axios.get(`${process.env.REACT_APP_API}/admin/movies`, headers());
};

const createMovie = (form) => {
  return axios.post(
    `${process.env.REACT_APP_API}/admin/movies`,
    form,
    headers()
  );
};

export { getMovies, createMovie };
