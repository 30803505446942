import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// services
import { getMovies } from "services/admin/Movie";
import { confirmModal } from "services/general";

// components
import TFade from "components/Transition/Fade";
import Modal from "components/Modal";
import Button from "components/Button";

const ListFilmAdmin = () => {
  const [data, setData] = useState([]);
  const [showModalInsert, setShowModalInsert] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await getMovies();
      setData(
        response.data.body.data.movies.map((movie) => {
          movie.genres = JSON.parse(movie.genres);
          return movie;
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  const deleteMovie = () => {
    confirmModal().then((result) => {
      if (result.isConfirmed) {
      }
    });
  };

  return (
    <TFade>
      <div className="mb-10 flex items-center justify-between gap-4">
        <div>
          <h1 className="text-2xl font-semibold">Daftar Film</h1>
        </div>
        <div>
          <div
            onClick={() => setShowModalInsert(true)}
            className="action bg-dark-blue"
          >
            <svg
              width="19"
              height="19"
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19 10.8571H10.8571V19H8.14286V10.8571H0V8.14286H8.14286V0H10.8571V8.14286H19V10.8571Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
      </div>

      {data.map((movie, index) => (
        <>
          <div
            className="flex items-center justify-between"
            key={`movie-${index}`}
          >
            <div className="flex items-center gap-6">
              <div>
                <img
                  src={movie.poster}
                  alt={movie.title}
                  className="h-[200px] w-full rounded-lg object-cover"
                />
              </div>
              <div>
                <h2 className="mb-2 text-lg font-semibold">{movie.title}</h2>
                <span className="mb-5 block">
                  {movie.genres.join(", ")} ◎ {movie.duration} ◎{" "}
                  {movie.age_rating}+
                </span>
                <div className="flex items-center gap-2">
                  <svg
                    width="17"
                    height="21"
                    viewBox="0 0 17 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.85659 11.9285C8.15022 11.9285 7.45973 11.719 6.87241 11.3266C6.28509 10.9342 5.82733 10.3764 5.55702 9.7238C5.2867 9.07121 5.21598 8.35311 5.35378 7.66032C5.49159 6.96753 5.83173 6.33117 6.33121 5.83169C6.83068 5.33222 7.46705 4.99207 8.15984 4.85427C8.85262 4.71647 9.57072 4.78719 10.2233 5.0575C10.8759 5.32782 11.4337 5.78558 11.8261 6.3729C12.2186 6.96021 12.428 7.65071 12.428 8.35707C12.4269 9.30393 12.0502 10.2117 11.3807 10.8812C10.7112 11.5507 9.80344 11.9274 8.85659 11.9285V11.9285ZM8.85659 6.21422C8.43277 6.21422 8.01847 6.33989 7.66608 6.57535C7.31369 6.81081 7.03903 7.14548 6.87684 7.53704C6.71466 7.92859 6.67222 8.35945 6.7549 8.77513C6.83759 9.1908 7.04167 9.57262 7.34136 9.8723C7.64104 10.172 8.02286 10.3761 8.43854 10.4588C8.85421 10.5414 9.28507 10.499 9.67662 10.3368C10.0682 10.1746 10.4028 9.89997 10.6383 9.54758C10.8738 9.19519 10.9994 8.78089 10.9994 8.35707C10.9989 7.78893 10.7729 7.24421 10.3712 6.84247C9.96945 6.44073 9.42473 6.21479 8.85659 6.21422V6.21422Z"
                      fill="#9DA8BE"
                      stroke="#9DA8BE"
                      stroke-width="0.25"
                    />
                    <path
                      d="M8.85715 20.5L2.83144 13.3936C2.74771 13.2869 2.66485 13.1795 2.58287 13.0714C1.55357 11.7156 0.997513 10.0594 1.00001 8.35714C1.00001 6.2733 1.82781 4.2748 3.30131 2.8013C4.77481 1.3278 6.77331 0.5 8.85715 0.5C10.941 0.5 12.9395 1.3278 14.413 2.8013C15.8865 4.2748 16.7143 6.2733 16.7143 8.35714C16.7168 10.0587 16.161 11.714 15.1322 13.0693L15.1314 13.0714C15.1314 13.0714 14.9172 13.3529 14.885 13.3907L8.85715 20.5ZM3.72287 12.2107C3.72287 12.2107 3.89001 12.4307 3.92787 12.4779L8.85715 18.2914L13.7929 12.47C13.8243 12.4307 13.9922 12.2086 13.9922 12.2086C14.833 11.1008 15.2874 9.74788 15.2857 8.35714C15.2857 6.65218 14.6084 5.01705 13.4028 3.81146C12.1972 2.60587 10.5621 1.92857 8.85715 1.92857C7.15219 1.92857 5.51706 2.60587 4.31147 3.81146C3.10587 5.01705 2.42858 6.65218 2.42858 8.35714C2.42708 9.74874 2.88128 11.1024 3.72287 12.2107Z"
                      fill="#9DA8BE"
                      stroke="#9DA8BE"
                      stroke-width="0.5"
                    />
                  </svg>

                  <span className="text-[#9DA8BE]">CinéTix</span>
                  <span className="font-medium">(Regular 2D)</span>
                </div>
              </div>
            </div>

            <div className="flex gap-3">
              <Link to="detail" className="action bg-blue">
                <svg
                  width="22"
                  height="16"
                  viewBox="0 0 22 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21.9102 7.219C21.8774 7.1451 21.1004 5.37069 19.3842 3.60539C17.0878 1.24711 14.1937 0 11.0007 0C7.80763 0 4.91349 1.24711 2.61983 3.60539C0.903572 5.37069 0.126601 7.1451 0.0911225 7.219C0.0310341 7.35912 0 7.51059 0 7.66374C0 7.81689 0.0310341 7.96836 0.0911225 8.10849C0.12394 8.1833 0.900912 9.9568 2.61806 11.7221C4.91349 14.0804 7.80763 15.3266 11.0007 15.3266C14.1937 15.3266 17.0878 14.0804 19.3806 11.7221C21.0978 9.9568 21.8747 8.1833 21.9075 8.10849C21.968 7.96855 21.9995 7.81718 22 7.66403C22.0005 7.51088 21.9699 7.35931 21.9102 7.219ZM17.8249 10.2296C15.9206 12.1582 13.6252 13.1371 11.0007 13.1371C8.37616 13.1371 6.08073 12.1582 4.1791 10.2287C3.43081 9.4671 2.78714 8.604 2.26593 7.66329C2.7873 6.72295 3.43095 5.86017 4.1791 5.09882C6.08161 3.1684 8.37616 2.18951 11.0007 2.18951C13.6252 2.18951 15.9197 3.1684 17.8222 5.09882C18.5704 5.8601 19.2141 6.72289 19.7354 7.66329C19.2141 8.60395 18.5704 9.46704 17.8222 10.2287L17.8249 10.2296ZM11.0007 3.64918C10.2288 3.64918 9.47427 3.88461 8.83249 4.32568C8.19071 4.76676 7.69051 5.39367 7.39513 6.12716C7.09975 6.86064 7.02247 7.66774 7.17305 8.4464C7.32363 9.22506 7.69532 9.9403 8.24111 10.5017C8.7869 11.0631 9.48227 11.4454 10.2393 11.6003C10.9963 11.7551 11.781 11.6757 12.4941 11.3718C13.2072 11.068 13.8167 10.5535 14.2456 9.8934C14.6744 9.23329 14.9033 8.4572 14.9033 7.66329C14.9021 6.59905 14.4905 5.57875 13.7589 4.82622C13.0273 4.07369 12.0353 3.65039 11.0007 3.64918ZM11.0007 9.48788C10.6498 9.48788 10.3068 9.38087 10.0151 9.18038C9.72341 8.97989 9.49605 8.69493 9.36178 8.36153C9.22752 8.02813 9.19239 7.66126 9.26084 7.30733C9.32929 6.95339 9.49823 6.62828 9.74632 6.37311C9.9944 6.11793 10.3105 5.94416 10.6546 5.87375C10.9987 5.80335 11.3554 5.83948 11.6795 5.97758C12.0036 6.11568 12.2807 6.34954 12.4756 6.6496C12.6705 6.94965 12.7746 7.30242 12.7746 7.66329C12.7746 8.1472 12.5877 8.61129 12.255 8.95347C11.9223 9.29565 11.4711 9.48788 11.0007 9.48788Z"
                    fill="white"
                  />
                </svg>
              </Link>
              <div
                onClick={() => setShowModalEdit(true)}
                className="action bg-yellow"
              >
                <svg
                  width="19"
                  height="15"
                  viewBox="0 0 19 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.9286 0L11.6071 1.875L16.25 5.625L18.5714 3.75L13.9286 0ZM9.28571 3.75L0 11.25V15H4.64286L13.9286 7.5L9.28571 3.75Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div onClick={() => deleteMovie()} className="action bg-red">
                <svg
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 0C7.35 0 6 1.07853 6 2.39674H3C1.35 2.39674 0 3.47527 0 4.79348H21C21 3.47527 19.65 2.39674 18 2.39674H15C15 1.07853 13.65 0 12 0H9ZM3 7.19022V18.7185C3 18.9822 3.24 19.1739 3.57 19.1739H17.46C17.79 19.1739 18.03 18.9822 18.03 18.7185V7.19022H15.03V15.5788C15.03 16.2499 14.37 16.7772 13.53 16.7772C12.69 16.7772 12.03 16.2499 12.03 15.5788V7.19022H9.03V15.5788C9.03 16.2499 8.37 16.7772 7.53 16.7772C6.69 16.7772 6.03 16.2499 6.03 15.5788V7.19022H3.03H3Z"
                    fill="white"
                  />
                </svg>
              </div>
            </div>
          </div>

          <div className="divider"></div>
        </>
      ))}

      <Modal
        name="Tambah Film"
        showModal={showModalInsert}
        setShowModal={(value) => setShowModalInsert(value)}
        body={
          <>
            <form>
              <div className="mb-5">
                <label
                  htmlFor="name"
                  className="text-gray-900 block text-sm font-medium leading-6"
                >
                  Nama
                </label>
                <div className="mt-2">
                  <input
                    id="name"
                    name="name"
                    type="text"
                    placeholder="Masukkan Nama"
                    required
                    className="text-gray-900 ring-gray-300 placeholder:text-gray-400 block w-full rounded-md border-0 py-2 px-2 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="mb-5">
                <label
                  htmlFor="genre"
                  className="text-gray-900 block text-sm font-medium leading-6"
                >
                  Genre
                </label>
                <div className="mt-2">
                  <input
                    id="genre"
                    name="genre"
                    type="text"
                    placeholder="Masukkan Genre (pisahkan dengan koma)"
                    required
                    className="text-gray-900 ring-gray-300 placeholder:text-gray-400 block w-full rounded-md border-0 py-2 px-2 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="mb-5">
                <label
                  htmlFor="duration"
                  className="text-gray-900 block text-sm font-medium leading-6"
                >
                  Durasi
                </label>
                <div className="mt-2">
                  <input
                    id="duration"
                    name="duration"
                    type="text"
                    placeholder="Masukkan Durasi"
                    required
                    className="text-gray-900 ring-gray-300 placeholder:text-gray-400 block w-full rounded-md border-0 py-2 px-2 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="mb-5">
                <label
                  htmlFor="age_rating"
                  className="text-gray-900 block text-sm font-medium leading-6"
                >
                  Rating Usia
                </label>
                <div className="mt-2">
                  <input
                    id="age_rating"
                    name="age_rating"
                    type="number"
                    placeholder="Masukkan Rating Usia"
                    required
                    className="text-gray-900 ring-gray-300 placeholder:text-gray-400 block w-full rounded-md border-0 py-2 px-2 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="mb-5">
                <label
                  htmlFor="rating"
                  className="text-gray-900 block text-sm font-medium leading-6"
                >
                  Rating Film
                </label>
                <div className="mt-2">
                  <input
                    id="rating"
                    name="rating"
                    type="number"
                    placeholder="Masukkan Rating Film"
                    required
                    className="text-gray-900 ring-gray-300 placeholder:text-gray-400 block w-full rounded-md border-0 py-2 px-2 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="mb-5">
                <label
                  htmlFor="poster"
                  className="text-gray-900 block text-sm font-medium leading-6"
                >
                  Poster Film
                </label>
                <div className="mt-2">
                  <input id="poster" name="poster" type="file" required />
                </div>
              </div>

              <Button type="submit" isBlock isPrimary>
                Kirim
              </Button>
            </form>
          </>
        }
      />

      <Modal
        name="Edit Film"
        showModal={showModalEdit}
        setShowModal={(value) => setShowModalEdit(value)}
        body={
          <>
            <form>
              <div className="mb-5">
                <label
                  htmlFor="name"
                  className="text-gray-900 block text-sm font-medium leading-6"
                >
                  Nama
                </label>
                <div className="mt-2">
                  <input
                    id="name"
                    name="name"
                    type="text"
                    placeholder="Masukkan Nama"
                    required
                    className="text-gray-900 ring-gray-300 placeholder:text-gray-400 block w-full rounded-md border-0 py-2 px-2 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="mb-5">
                <label
                  htmlFor="genre"
                  className="text-gray-900 block text-sm font-medium leading-6"
                >
                  Genre
                </label>
                <div className="mt-2">
                  <input
                    id="genre"
                    name="genre"
                    type="text"
                    placeholder="Masukkan Genre (pisahkan dengan koma)"
                    required
                    className="text-gray-900 ring-gray-300 placeholder:text-gray-400 block w-full rounded-md border-0 py-2 px-2 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="mb-5">
                <label
                  htmlFor="duration"
                  className="text-gray-900 block text-sm font-medium leading-6"
                >
                  Durasi
                </label>
                <div className="mt-2">
                  <input
                    id="duration"
                    name="duration"
                    type="text"
                    placeholder="Masukkan Durasi"
                    required
                    className="text-gray-900 ring-gray-300 placeholder:text-gray-400 block w-full rounded-md border-0 py-2 px-2 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="mb-5">
                <label
                  htmlFor="age_rating"
                  className="text-gray-900 block text-sm font-medium leading-6"
                >
                  Rating Usia
                </label>
                <div className="mt-2">
                  <input
                    id="age_rating"
                    name="age_rating"
                    type="number"
                    placeholder="Masukkan Rating Usia"
                    required
                    className="text-gray-900 ring-gray-300 placeholder:text-gray-400 block w-full rounded-md border-0 py-2 px-2 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="mb-5">
                <label
                  htmlFor="rating"
                  className="text-gray-900 block text-sm font-medium leading-6"
                >
                  Rating Film
                </label>
                <div className="mt-2">
                  <input
                    id="rating"
                    name="rating"
                    type="number"
                    placeholder="Masukkan Rating Film"
                    required
                    className="text-gray-900 ring-gray-300 placeholder:text-gray-400 block w-full rounded-md border-0 py-2 px-2 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="mb-5">
                <label
                  htmlFor="poster"
                  className="text-gray-900 block text-sm font-medium leading-6"
                >
                  Poster Film
                </label>
                <div className="mt-2">
                  <input id="poster" name="poster" type="file" required />
                </div>
              </div>

              <Button type="submit" isBlock isPrimary>
                Kirim
              </Button>
            </form>
          </>
        }
      />
    </TFade>
  );
};
export default ListFilmAdmin;
