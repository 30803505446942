import { useEffect, useState } from "react";

// services
import { getReviews } from "services/admin/Review";

// components
import TFade from "components/Transition/Fade";

// asset
import tesfoto from "assets/images/thumbnail.png";

const CustomerReview = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await getReviews();
      setData(response.data.body.data.reviews);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <TFade>
      <h1 className="mb-10 text-4xl font-semibold">Keluhan Pelanggan</h1>

      {data.map((review, index) => (
        <>
          <div className="flex items-center gap-5" key={`review-${index}`}>
            <div className="cols-start-1">
              <div className="h-20 w-20 overflow-hidden rounded-full">
                <img
                  src={`${process.env.REACT_APP_STORAGE}avatars/${review.user.avatar}`}
                  alt={review.user.name}
                />
              </div>
            </div>
            <div className="col-span-10 col-start-3 ml-2">
              <b>{review.user.name}</b>
              <div className="col-start-3 text-gray">{review.message}</div>
            </div>
          </div>

          <div className="divider"></div>
        </>
      ))}
    </TFade>
  );
};
export default CustomerReview;
